<template>
  <div class="library-documents__container">
    <VRow>
      <VCol
        v-for="book in courseBooks"
        :key="book.id"
        xl="3"
        lg="4"
        sm="6"
        xs="12"
      >
        <RouterLink
          class="library-documents__link"
          :to="`/library/book/${book.id}`"
        >
          <div class="library-documents">
            <VCard class="library-documents__card">
              <div class="library-documents__img-group">
                <img
                  v-if="book.cover"
                  class="library-documents__img"
                  :src="`/${ book.cover }`"
                  :alt="book.title"
                >
                <img
                  v-else
                  class="library-documents__img-default"
                  src="/assets/images/default-document-cover.svg"
                  :alt="book.title"
                >
              </div>
              <div class="library-documents__items">
                <VCardTitle
                  v-show="book.title"
                  :title="book.title"
                  class="library-documents__card-title"
                >
                  {{ promotedLibraryName (book.title, 60) }}
                </VCardTitle>
                <VCardText
                  v-show="book.author"
                  class="library-documents__card-text-author"
                >
                  <strong>{{ $t('$app.library_author') + ':' }} </strong>{{ promotedLibraryName (book.author, 20) }}
                </VCardText>
                <VCardText
                  v-show="book.publication_year"
                  class="library-documents__card-text-year"
                >
                  <strong>{{ $t('$app.library_publication_year') + ':' }} </strong>{{ book.publication_year }}
                </VCardText>
              </div>
            </VCard>
          </div>
        </RouterLink>
      </VCol>
    </VRow>
  </div>
</template>

<script>

import { shortenedString } from '@/utils/common'

export default {
  name: 'ULibraryDocuments',
  props: {
    courseBooks: {
      type: Array,
      default: () => []
    }
  },

  methods: {
    promotedLibraryName (book, maxLength) {
      return shortenedString(book, maxLength)
    }
  },

}
</script>

<style lang="scss" scoped>
.library-documents__container {
  width: 100%;
  padding: 0.75rem;
  margin-right: auto;
  margin-left: auto;
}

.library-documents__card {
  display: flex;
  height: 100%;
  transition: linear 0.1s;
}
</style>
